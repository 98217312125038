import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a7d6302"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-label", "data-ratio"]
const _hoisted_2 = ["data-bet-all", "data-bet-u"]
const _hoisted_3 = { class: "chips-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ctrl-bet-box-root", _ctx.betColor]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.placeBet && _ctx.placeBet(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: "label-ratio",
      "data-label": _ctx.label,
      "data-ratio": _ctx.ratio
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "bet-infos",
      "data-bet-all": `单:${_ctx.sumOfTempBetandConfirmedBet}`,
      "data-bet-u": `总: ${_ctx.totalBet ?? 0}`
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chipsUI, (chip, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["chip", chip.class]),
          key: index,
          style: _normalizeStyle(`top:var(--pc${chip.y});left:var(--pc${chip.x});`)
        }, _toDisplayString(_ctx.toShortValue(chip.amount)), 7))
      }), 128))
    ])
  ], 2))
}