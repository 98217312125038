
import {
    ChipOption,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    TableData,
    toDisplayString,
    watch
} from 'vue'
import CtrlBetBox from '@/components/ctrl-bet-box.vue'
import { useStore } from '@/store'
import { BetType, EventBuss } from '@/types/global'
import BetStartStopPop from '@/components/bet-start-stop-pop.vue'
import { saveBet } from '@/api'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { AppMutation } from '@/store/types'

export default defineComponent({
    emits: ['on-update', 'on-bet-success'],
    components: {
        CtrlBetBox,
        BetStartStopPop
    },
    props: {
        gameType: {
            type: String as PropType<string>,
            required: false,
            default: ''
        },
        mData: {
            type: Object as PropType<TableData>,
            require: false
        }
    },
    setup(props, { emit }) {
        const store = useStore()
        const emitter = inject('emitter') as Emitter

        const pBet = ref<number>(0)
        const bBet = ref<number>(0)
        const ppBet = ref<number>(0)
        const bpBet = ref<number>(0)
        const tBet = ref<number>(0)
        const drBet = ref<number>(0)
        const tgBet = ref<number>(0)
        const tempPBet = ref<number>(0)
        const tempBBet = ref<number>(0)
        const tempPpBet = ref<number>(0)
        const tempBpBet = ref<number>(0)
        const tempTBet = ref<number>(0)
        const tempDrBet = ref<number>(0)
        const tempTgBet = ref<number>(0)
        const showStartAndEndNorify = ref<boolean>(false)
        const timerNotifyStatus = ref<number>(1)

        const bankerBox = ref<any>()
        const playerBox = ref<any>()
        const tieBox = ref<any>()
        const bankerPBox = ref<any>()
        const playerPBox = ref<any>()

        const dragonBox = ref<any>()
        const tigerBox = ref<any>()
        const tieTGBox = ref<any>()

        const isBetOnProcess = ref(false)

        const toastMessage = ref<{ id: number | boolean; message: string }[]>(
            []
        )

        onMounted(() => {
            emitter.on(EventBuss.SMALL_TOAST, handleToastMessage)
            emitter.on(EventBuss.GAMESTATUS, handleNotifyStatus)
            removeallToast()
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.SMALL_TOAST, handleToastMessage)
            emitter.off(EventBuss.GAMESTATUS, handleNotifyStatus)
        })

        const handleNotifyStatus = (status: number) => {
            timerNotifyStatus.value = status
            showStartAndEndNorify.value = true

            resetBet()
            resetTempBet()
        }

        const removeallToast = () => {
            toastMessage.value.forEach((toastM) => {
                if (typeof toastM.id === 'number') {
                    clearTimeout(toastM.id)
                    toastM.id = false
                }
            })

            toastMessage.value = []
        }

        const placeBet = (_betType: number) => {
            // check if user can bet
            if (!props.mData) return

            const { mData } = props

            if (userCommandType.value === 1) {
                if (mData.expand.callGunner === 2) {
                    // client betting not allowed if value is 2
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '当前由枪手下注，请切换模式'
                    )
                    return
                }

                // console.log(mData.expand.stopBet)

                if (mData.expand.stopBet === 1) {
                    emitter.emit(EventBuss.SMALL_TOAST, '当前不能下注')

                    // console.log(userCommandType.value)
                    return
                }

                if (mData.expand.openPoke === 1) {
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '已确认开牌，无法再投注'
                    )

                    // console.log(userCommandType.value)
                    return
                }
            }

            if (isFreeUser.value) {
                emitter.emit(
                    EventBuss.SMALL_TOAST,
                    '当前由枪手下注，请切换模式'
                )
                return
            }

            // check if its allowed to bet
            if (mData.status !== 1 && userCommandType.value !== 1) {
                // countdown timer is done, no betting allowed
                emitter.emit(EventBuss.SMALL_TOAST, '当前不能下注')
                return
            }

            const _amount = chipValue.value || 0
            if (_amount > 0) {
                switch (_betType) {
                    case BetType.BANKER:
                        tempBBet.value += _amount
                        break
                    case BetType.DRAGON:
                        tempDrBet.value += _amount
                        break
                    case BetType.PLAYER:
                        tempPBet.value += _amount
                        break
                    case BetType.TIGER:
                        tempTgBet.value += _amount
                        break
                    case BetType.BC_TIE:
                    case BetType.DT_TIE:
                        tempTBet.value += _amount
                        break
                    case BetType.PLAYER_PAIR:
                        tempPpBet.value += _amount
                        break
                    case BetType.BANKER_PAIR:
                        tempBpBet.value += _amount
                        break
                }

                emitter.emit(EventBuss.PLACE_CHIP, {
                    t: _betType,
                    amt: _amount,
                    type: 'temp'
                })
            }

            callUpdateCallback()
        }

        const callUpdateCallback = () => {
            const hasConfimedBet =
                pBet.value +
                    bBet.value +
                    ppBet.value +
                    bpBet.value +
                    tBet.value +
                    drBet.value +
                    tgBet.value >
                0

            const hasTempBet =
                tempPBet.value +
                    tempBBet.value +
                    tempPpBet.value +
                    tempBpBet.value +
                    tempTBet.value +
                    tempDrBet.value +
                    tempTgBet.value >
                0

            emit('on-update', hasConfimedBet || hasTempBet)
        }

        const handleToastMessage = (_message: string) => {
            const _t = {
                id: setTimeout(() => {
                    const _tt = toastMessage.value.shift()
                    if (_tt && typeof _tt.id === 'number') {
                        clearTimeout(_tt.id)
                    }
                }, 1500),
                message: _message
            }
            toastMessage.value.push(_t)
        }

        const resetBetUI = () => {
            if (props.gameType === 'G002') {
                // bac
                if (bankerBox.value && bBet.value > 0)
                    bankerBox.value.handleOnPlaceChip({
                        t: BetType.BANKER,
                        amt: bBet.value,
                        type: 'confirmed'
                    })
                if (playerBox.value && pBet.value > 0) {
                    playerBox.value.handleOnPlaceChip({
                        t: BetType.PLAYER,
                        amt: pBet.value,
                        type: 'confirmed'
                    })
                }

                if (tieBox.value && tBet.value > 0) {
                    tieBox.value.handleOnPlaceChip({
                        t: BetType.BC_TIE,
                        amt: tBet.value,
                        type: 'confirmed'
                    })
                }

                if (bankerPBox.value && bpBet.value > 0) {
                    bankerPBox.value.handleOnPlaceChip({
                        t: BetType.BANKER_PAIR,
                        amt: bpBet.value,
                        type: 'confirmed'
                    })
                }

                if (playerPBox.value && ppBet.value > 0) {
                    playerPBox.value.handleOnPlaceChip({
                        t: BetType.PLAYER_PAIR,
                        amt: ppBet.value,
                        type: 'confirmed'
                    })
                }
            } else {
                if (tigerBox.value && tgBet.value > 0) {
                    tigerBox.value.handleOnPlaceChip({
                        t: BetType.TIGER,
                        amt: tgBet.value,
                        type: 'confirmed'
                    })
                }

                if (dragonBox.value && drBet.value > 0) {
                    dragonBox.value.handleOnPlaceChip({
                        t: BetType.DRAGON,
                        amt: drBet.value,
                        type: 'confirmed'
                    })
                }

                if (tieTGBox.value && tBet.value > 0) {
                    dragonBox.value.handleOnPlaceChip({
                        t: BetType.DT_TIE,
                        amt: tBet.value,
                        type: 'confirmed'
                    })
                }
            }
        }

        const resetBet = () => {
            pBet.value = 0
            bBet.value = 0
            ppBet.value = 0
            bpBet.value = 0
            tBet.value = 0
            drBet.value = 0
            tgBet.value = 0
        }

        const resetTempBet = () => {
            tempPBet.value = 0
            tempBBet.value = 0
            tempPpBet.value = 0
            tempBpBet.value = 0
            tempTBet.value = 0
            tempDrBet.value = 0
            tempTgBet.value = 0

            // bac
            if (bankerBox.value) bankerBox.value.removeTempUIBet()
            if (playerBox.value) playerBox.value.removeTempUIBet()
            if (tieBox.value) tieBox.value.removeTempUIBet()
            if (bankerPBox.value) bankerPBox.value.removeTempUIBet()
            if (playerPBox.value) playerPBox.value.removeTempUIBet()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.removeTempUIBet()
            if (tigerBox.value) tigerBox.value.removeTempUIBet()
            if (tieTGBox.value) tieTGBox.value.removeTempUIBet()

            callUpdateCallback()
        }

        const applyTempBet = () => {
            tempPBet.value = 0
            tempBBet.value = 0
            tempPpBet.value = 0
            tempBpBet.value = 0
            tempTBet.value = 0
            tempDrBet.value = 0
            tempTgBet.value = 0

            if (bankerBox.value) bankerBox.value.applyTempUIBet()
            if (playerBox.value) playerBox.value.applyTempUIBet()
            if (tieBox.value) tieBox.value.applyTempUIBet()
            if (bankerPBox.value) bankerPBox.value.applyTempUIBet()
            if (playerPBox.value) playerPBox.value.applyTempUIBet()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.applyTempUIBet()
            if (tigerBox.value) tigerBox.value.applyTempUIBet()
            if (tieTGBox.value) tieTGBox.value.applyTempUIBet()
        }

        const confirmTempBet = () => {
            if (isBetOnProcess.value) return
            let _params: any = {}
            if (props.gameType === 'G002') {
                _params = {
                    memberId: memberId.value,
                    tableId: props.mData!.id,
                    shoeNo: props.mData!.expand.shoeNo,
                    juNo: props.mData!.expand.juNo,
                    gameNo: props.mData!.expand.gameNo,
                    zhuang: tempBBet.value,
                    he: tempTBet.value,
                    xian: tempPBet.value,
                    zhuangDui: tempBpBet.value,
                    xianDui: tempPpBet.value,
                    token: token.value
                }
            } else if (props.gameType === 'G003') {
                _params = {
                    memberId: memberId.value,
                    tableId: props.mData!.id,
                    shoeNo: props.mData!.expand.shoeNo,
                    juNo: props.mData!.expand.juNo,
                    gameNo: props.mData!.expand.gameNo,
                    zhuang: tempDrBet.value,
                    he: tempTBet.value,
                    xian: tempTgBet.value,
                    token: token.value
                }
            }

            store.commit(AppMutation.SHOW_LOADING)
            isBetOnProcess.value = true
            saveBet(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    isBetOnProcess.value = false
                    if (response.data.status === 100) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            response.data.description
                        )
                        resetTempBet()
                    } else if (response.data.status === 200) {
                        emitter.emit(EventBuss.SMALL_TOAST, '下注成功')
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS)

                        applyTempBet()
                        emit('on-bet-success')
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    isBetOnProcess.value = false
                    resetTempBet()
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const endGame = () => {
            // bac
            if (bankerBox.value) bankerBox.value.resetBetUI()
            if (playerBox.value) playerBox.value.resetBetUI()
            if (tieBox.value) tieBox.value.resetBetUI()
            if (bankerPBox.value) bankerPBox.value.resetBetUI()
            if (playerPBox.value) playerPBox.value.resetBetUI()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.resetBetUI()
            if (tigerBox.value) tigerBox.value.resetBetUI()
            if (tieTGBox.value) tieTGBox.value.resetBetUI()

            resetTempBet()
        }

        const bankerTotal = computed((): number => {
            if (props.mData && props.mData.expand.zhuangAmount)
                return props.mData.expand.zhuangAmount

            return 0
        })

        const playerTotal = computed((): number => {
            if (props.mData && props.mData.expand.xianAmount)
                return props.mData.expand.xianAmount
            return 0
        })

        const tieTotal = computed((): number => {
            if (props.mData && props.mData.expand.heAmount)
                return props.mData.expand.heAmount
            return 0
        })

        const playerPairAmount = computed((): number => {
            if (props.mData && props.mData.expand.xianDuiAmount)
                return props.mData.expand.xianDuiAmount
            return 0
        })

        const bankerPairAmount = computed(() => {
            if (props.mData && props.mData.expand.zhuangDuiAmount)
                return props.mData.expand.zhuangDuiAmount
            return 0
        })

        const chips = computed((): ChipOption => store.getters['chips'])
        const currentChip = computed((): number => store.getters['currentChip'])
        const customChip = computed(() => store.getters['customChip'])
        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const userCommandType = computed(() => store.getters['commandType'])
        const isFreeUser = computed((): boolean => store.getters['isFreeUser'])

        const chipValue = computed(() => {
            if (currentChip.value === 22 && customChip.value > 0) {
                return customChip.value
            }

            if (currentChip.value !== -1) {
                return chips.value.value[currentChip.value]
            }

            return 0
        })

        const totalTempBet = computed(() => {
            const _b = [
                tempPBet.value,
                tempBBet.value,
                tempPpBet.value,
                tempBpBet.value,
                tempTBet.value,
                tempDrBet.value,
                tempTgBet.value
            ].reduce((a, b) => a + b, 0)
            // console.log(_b)
            return _b
        })

        watch(
            () => props.mData,
            (val, old) => {
                if (val && val.expand.betFormList) {
                    let _pBet = 0
                    let _bBet = 0
                    let _ppBet = 0
                    let _bpBet = 0
                    let _tBet = 0
                    let _drBet = 0
                    let _tgBet = 0

                    const { betFormList } = val.expand
                    betFormList.forEach((i) => {
                        if (i.memberId === memberId.value) {
                            const { playName, amount } = i
                            if (playName === '庄') {
                                _bBet += amount
                            } else if (playName === '庄对') {
                                _bpBet += amount
                            } else if (playName === '闲') {
                                _pBet += amount
                            } else if (playName === '闲对') {
                                _ppBet += amount
                            } else if (playName === '和') {
                                _tBet += amount
                            } else if (playName === '龙') {
                                _drBet += amount
                            } else if (playName === '虎') {
                                _tgBet += amount
                            }
                        }
                    })
                    pBet.value = _pBet
                    bBet.value = _bBet
                    ppBet.value = _ppBet
                    bpBet.value = _bpBet
                    tBet.value = _tBet
                    drBet.value = _drBet
                    tgBet.value = _tgBet
                }
            },
            { immediate: true, deep: true }
        )

        return {
            isBetOnProcess,
            bankerTotal,
            playerTotal,
            tieTotal,
            playerPairAmount,
            bankerPairAmount,
            pBet,
            bBet,
            ppBet,
            bpBet,
            tBet,
            drBet,
            tgBet,
            tempPBet,
            tempBBet,
            tempPpBet,
            tempBpBet,
            tempTBet,
            tempDrBet,
            tempTgBet,
            toastMessage,
            totalTempBet,
            showStartAndEndNorify,
            timerNotifyStatus,
            bankerBox,
            playerBox,
            tieBox,
            bankerPBox,
            playerPBox,
            dragonBox,
            tigerBox,
            tieTGBox,
            endGame,
            resetBetUI,
            confirmTempBet,
            resetTempBet,
            placeBet
        }
    }
})
