import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34a83733"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-card-result-root"
}
const _hoisted_2 = ["data-point"]
const _hoisted_3 = ["data-point"]
const _hoisted_4 = ["data-color"]
const _hoisted_5 = {
  key: 1,
  class: "d-card-result-root"
}
const _hoisted_6 = ["data-point"]
const _hoisted_7 = ["data-point"]
const _hoisted_8 = ["data-color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.gameType === 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["player-result-group", { flashing: _ctx.winner === 3 }])
        }, [
          _createElementVNode("div", {
            class: "cards-point-and-label",
            "data-point": _ctx.blueTotal
          }, _toDisplayString(_ctx.t('player')), 9, _hoisted_2),
          (_ctx.blueFirstCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card1", _ctx.blueFirstCard])
              }, null, 2))
            : _createCommentVNode("", true),
          (_ctx.blueSecondCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["card2", _ctx.blueSecondCard])
              }, null, 2))
            : _createCommentVNode("", true),
          (_ctx.blueThirdCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["card3", _ctx.blueThirdCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["banker-result-group", { flashing: _ctx.winner === 1 }])
        }, [
          _createElementVNode("div", {
            class: "cards-point-and-label",
            "data-point": _ctx.redTotal
          }, _toDisplayString(_ctx.t('banker')), 9, _hoisted_3),
          (_ctx.redFirstCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card1", _ctx.redFirstCard])
              }, null, 2))
            : _createCommentVNode("", true),
          (_ctx.redSecondCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["card2", _ctx.redSecondCard])
              }, null, 2))
            : _createCommentVNode("", true),
          (_ctx.redThirdCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["card3", _ctx.redThirdCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: "result",
          "data-color": _ctx.winner
        }, _toDisplayString(_ctx.resultLabel), 9, _hoisted_4)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(["dragon-result-group", { flashing: _ctx.winner === 1 }])
        }, [
          _createElementVNode("div", {
            class: "cards-point-and-label",
            "data-point": _ctx.redTotal
          }, _toDisplayString(_ctx.t('dragon')), 9, _hoisted_6),
          (_ctx.redFirstCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card1", _ctx.redFirstCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tiger-result-group", { flashing: _ctx.winner === 3 }])
        }, [
          _createElementVNode("div", {
            class: "cards-point-and-label",
            "data-point": _ctx.blueTotal
          }, _toDisplayString(_ctx.t('tiger')), 9, _hoisted_7),
          (_ctx.blueFirstCard !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["card1", _ctx.blueFirstCard])
              }, null, 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: "result",
          "data-color": _ctx.winner
        }, _toDisplayString(_ctx.resultLabel), 9, _hoisted_8)
      ]))
}