
import { useStore } from '@/store'
import {
    computed,
    defineComponent,
    Emitter,
    HallData,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    ResponseData,
    ResultMemberList,
    SocketMessage,
    TableData,
    TableDetails,
    UserInfo,
    watch
} from 'vue'
import RoadMap from '@/components/roadmap/index.vue'
import DPrediction from '@/components/d-prediction.vue'
// import MediaControl from '@/components/media-control.vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
// import { ROUTES } from '@/router'
import { EventBuss, SOCKET_MESSAGE } from '@/types/global'
import { AppMutation } from '@/store/types'
import { RoadMapData } from '@/components/roadmap/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/roadmap/utils'
import { RoadMapSummaryData } from '@/components/roadmap/datamodel/types'
import { VTextMarquee } from 'vue-text-marquee'
// import DeskSwitcher from '@/components/switch-table.vue'
import { Dictionary } from '@/types/dictionary'
import { GameResult } from '@/types/GameResult'
// import SimpleGameResult from '@/components/simple-game-result.vue'
import CardGameResult from '@/components/d-card-result.vue'
import CardPreviewResult from '@/components/card-result-preview.vue'
import {
    AxiosSource,
    callGunner,
    fetch,
    flyBet,
    getTableDetail,
    openPokeRequest,
    operatStatus,
    pauseRequest
} from '@/api'
import axios from 'axios'
import { ROUTES } from '@/router'
import {
    IArrowH,
    IBalance,
    IUserL,
    IReloadG,
    ICard,
    IDiamond,
    IBalanceG,
    IHome,
    IFull,
    INormal,
    // IconPlayG,
    IconOpenCardG,
    IconPauseG
} from '@/components/icons'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'

import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import SwitchTable from '@/components/switch-table.vue'
import CtrlChips from '@/components/ctrl-chips.vue'
import CtrlBetBoxGroup from '@/components/ctrl-bet-box-group.vue'
import { joinTable as changeTable } from '@/api'
import screenfull from 'screenfull'

export default defineComponent({
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        }
    },
    components: {
        'road-map': RoadMap,
        'd-prediction': DPrediction,
        // 'media-control': MediaControl,
        'marquee-text': VTextMarquee,
        // 'desk-switcher': DeskSwitcher,
        // 'simple-game-result': SimpleGameResult,
        'card-game-result': CardGameResult,
        IArrowH,
        IBalance,
        IUserL,
        IReloadG,
        ICard,
        IDiamond,
        IBalanceG,
        IHome,
        INormal,
        IFull,
        SwitchTable,
        CtrlChips,
        CtrlBetBoxGroup,
        // IconPlayG,
        IconOpenCardG,
        IconPauseG,
        CardPreviewResult
    },
    setup(props) {
        const store = useStore()
        const { t } = useI18n()
        const router = useRouter()
        const route = useRoute()
        const emitter = inject('emitter') as Emitter
        const currentCount = ref<number>(-1)
        const deskMapKey = ref<number>(new Date().getTime())
        const refBetGroup = ref<any>()

        const roadmapview1 = ref<typeof RoadMap | undefined>(undefined)
        const roadmapview2 = ref<typeof RoadMap | undefined>(undefined)
        const deskData = ref<TableDetails | undefined>(undefined)
        const roadMapData = ref<RoadMapData>(parseRoadMap([]))
        const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const deskVideoOn = ref<boolean>(false)
        const showResultPreview = ref<boolean>(false)

        const showSwitcher = ref<boolean>(false)
        const hasJoinRequest = ref<boolean>(false)

        const isScaleVideo = ref<boolean>(false)
        const deskNoticeKey = ref<number>(new Date().getTime())
        const deskNotice = ref<string>('')

        let serviceTimeOut: boolean | number = false
        let countDownInterval: boolean | number = false

        // video variables
        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any
        const isLoadingVideo = ref<boolean>(true)

        let currentGameId: string | undefined = undefined
        let needRefreshGameResult = true
        let showCardTimeout: number | boolean = false
        let currentGameResult: GameResult | undefined = undefined
        const showSimpleResult = ref<boolean>(false)
        const showCardDisplay = ref<boolean>(false)
        const showMediaControl = ref<boolean>(false)
        const showSwitchDesk = ref<boolean>(false)
        const betViewKey = ref<number>(new Date().getTime())

        const cardResultKey = ref<number>(Date.now())
        const isPokerOpenFlag = ref(3)

        let isLoadingCallGunner = false

        let deskId = -1
        let loadDataFailedCounter = 0

        const enableOpenPoker = ref<boolean>(false)
        let calledOpenPokeCounter = 0

        //#region Vue Helpers
        onMounted(() => {
            deskId = props.desk
            deskVideoOn.value = videoIsOn.value
            loadData()

            setTimeout(() => {
                showMediaControl.value = true
            }, 1000)
        })
        onBeforeMount(() => {
            if (props.desk <= 0) {
                router.push({ name: ROUTES.LOBY })
                return
            }

            if (isFreeUser.value) {
                if (visitorTable.value.indexOf(props.desk) === -1) {
                    router.push({ name: ROUTES.LOBY })
                    return
                }
            }

            // listen to page onActive an in active state
            // emitter.on(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)

            emitter.on(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
            // listen to websocket events
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })

        onBeforeUnmount(() => {
            stopCountDown()
            stopService()
            disposeVideoPlayer()

            // emitter.off(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.off(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })
        //#endregion

        // const isActiveTableOption = (id: number) => {
        //     return props.desk === id
        // }

        // const handleOnPageActive=()=>{

        //     showCardDisplay.value = false
        //     disposeVideoPlayer()

        // }

        const loadData = (_forceReload = false) => {
            if (deskId <= 0) {
                router.push({ name: ROUTES.LOBY })
                return
            }

            const _params = {
                memberId: memberid.value,
                tableId: deskId,
                token: token.value
            }

            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)
            getTableDetail(_params)
                .then((_response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData =
                        _response.data as ResponseData<TableDetails>

                    if (responseData.status === 200) {
                        const { noticeList, gameTable } = responseData.data!

                        if (gameTable.reserverd === 1) {
                            if (userInfo.value && gameTable.visitCode) {
                                const visitCodes =
                                    gameTable.visitCode.split(',')
                                if (
                                    gameTable.visitCode === '' ||
                                    visitCodes.indexOf(userInfo.value.code) ==
                                        -1
                                ) {
                                    kickUserOffTheTable()
                                    return
                                }
                            }
                        }

                        let _deskNotice = ''
                        noticeList.forEach((_notice, index) => {
                            if (index === 0) _deskNotice = _notice.content
                            else
                                _deskNotice = `${_deskNotice},   ${_notice.content}`
                        })
                        deskNotice.value = _deskNotice
                        deskNoticeKey.value = new Date().getTime()

                        deskData.value = responseData.data!
                        store.commit(
                            AppMutation.SET_COIN,
                            deskData.value.gameTable.expand.amount
                        )
                        roadMapData.value = parseRoadMap(
                            deskData.value.gameTable.expand.resultList
                        )

                        store.commit(
                            AppMutation.SET_IS_GUNNER,
                            responseData.data?.isGunner
                        )

                        if (
                            responseData.data!.gameTable.expand.memberStatus ===
                            3
                        ) {
                            store.commit(
                                AppMutation.SET_DESK,
                                responseData.data!.gameTable.id
                            )
                        }

                        if (refBetGroup.value) {
                            setTimeout(() => {
                                refBetGroup.value.resetBetUI()

                                if (userCommandType.value === 1) {
                                    if (
                                        deskData.value &&
                                        deskData.value.gameTable.expand
                                            .openPoke === -1
                                    ) {
                                        enableOpenPoker.value = true
                                        // fix open card
                                        isPokerOpenFlag.value = 4
                                    } else {
                                        enableOpenPoker.value = false
                                        // fix open card
                                        isPokerOpenFlag.value = 3
                                    }
                                } else {
                                    if (
                                        deskData.value &&
                                        deskData.value.gameTable.expand
                                            .betFormList
                                    ) {
                                        if (
                                            deskData.value.gameTable.status ===
                                            1
                                        ) {
                                            deskData.value.gameTable.expand.betFormList.forEach(
                                                (i) => {
                                                    if (
                                                        i.memberId ===
                                                        memberid.value
                                                    ) {
                                                        enableOpenPoker.value =
                                                            true
                                                        // fix open card
                                                        isPokerOpenFlag.value = 4
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }
                            }, 250)
                        }

                        if (deskVideoOn.value) initVideoPlayer()
                    } else if (responseData.status === 301) {
                        // token is expired
                        // reset
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    // emitter.emit(EventBuss.TOAST_MESSAGE, '网络错误')

                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const stopService = () => {
            if (AxiosSource) {
                AxiosSource.Source.cancel()
                AxiosSource.Source = axios.CancelToken.source()
            }

            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }
        }

        const onRoadMapDataParsed = (
            a: RoadMapData,
            b: RoadMapData,
            c: RoadMapData
        ) => {
            roadMapData.value = a
            nextRedRoadMapSummaryData.value = { data: b }
            nextBlueRoadMapSummaryData.value = { data: c }
        }

        const predict = (preType: number) => {
            if (roadmapview1.value) {
                roadmapview1.value.Predict(preType)
            }

            if (roadmapview2.value) {
                roadmapview2.value.Predict(preType)
            }
        }

        const handleOnWindowResize = () => {
            deskMapKey.value = new Date().getTime()
            deskNoticeKey.value = new Date().getTime()
        }

        const hideSwitcher = () => {
            showSwitcher.value = false
        }

        const onToggleMediaControl = () => {
            showMediaControl.value = !showMediaControl.value
        }

        const onPlaceTempBetOrClear = (_hasTempAndConfirmBet: boolean) => {
            //  userCommandType.value === 2 &&
            // if (
            //     isYoureReserved.value &&
            //     _hasTempAndConfirmBet &&
            //     deskData.value!.gameTable.status === 1
            // ) {
            //     enableOpenPoker.value = true
            // } else {
            //     enableOpenPoker.value = false
            // }
        }

        const onBetSuccess = () => {
            // do something
            if (
                isYoureReserved.value &&
                deskData.value!.gameTable.status === 1 &&
                isPokerOpenFlag.value === 3
            ) {
                enableOpenPoker.value = true
                isPokerOpenFlag.value = 4
            } else if (
                (!isReserved.value &&
                    deskData.value!.gameTable.status === 1 &&
                    isPokerOpenFlag.value === 3) ||
                (isReserved.value &&
                    isYoureReserved &&
                    deskData.value!.gameTable.status === 1 &&
                    isPokerOpenFlag.value === 3)
            ) {
                enableOpenPoker.value = true
                isPokerOpenFlag.value = 4
            } else if (
                userCommandType.value === 1 &&
                deskData.value!.gameTable.status === 2
            ) {
                enableOpenPoker.value = true
                isPokerOpenFlag.value = 4
            } else {
                // console.log('show')
            }
        }

        const onSwitchTable = (_tableNo: number, _desk: TableData) => {
            // check member type if 1= free user can enter all desk
            if (_desk.reserverd === 1) {
                if (
                    (userInfo.value && _desk.visitCode) ||
                    _desk.visitCode === ''
                ) {
                    const visitCodes = _desk.visitCode.split(',')
                    if (visitCodes.indexOf(userInfo.value.code) === -1) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '该台桌已被包桌，请选择其他台桌进行投注'
                        )
                        return
                    }
                }
            } else if (userInfo.value.memberType !== 1) {
                if (
                    _desk.commandType !== 0 &&
                    userCommandType.value !== _desk.commandType
                ) {
                    let _toastMessage = `${_desk.tableNo}`

                    if (_desk.commandType === 1) {
                        _toastMessage += '号台桌为电投台,请选择其他台桌游戏！'
                    } else {
                        _toastMessage += '号台桌为指令台,请选择其他台桌游戏！'
                    }
                    emitter.emit(EventBuss.TOAST_MESSAGE, _toastMessage)

                    return
                }
            }

            showSwitchDesk.value = false

            if (props.desk === _tableNo) return

            hasJoinRequest.value = false
            betViewKey.value = new Date().getTime()
            showResultPreview.value = false

            router.replace({
                query: {
                    desk: _tableNo
                }
            })

            deskId = _tableNo
            stopCountDown()

            isScaleVideo.value = false
            deskVideoOn.value = videoIsOn.value
            disposeVideoPlayer()

            // disposeIngameToastMessage()
            // hide the card result when switching desk
            // showCardResult.value = false
            showCardDisplay.value = false
            // bet view reload
            // betViewKey.value = new Date().getTime()
            // currentGameId = undefined

            deskData.value = undefined
            // nextRedRoadMapSummaryData.value = {}
            // nextBlueRoadMapSummaryData.value = {}
            roadMapData.value = parseRoadMap([])
            loadData()
        }

        //#region Video Player Helpers
        const initVideoPlayer = () => {
            isLoadingVideo.value = true
            disposeVideoPlayer()

            const videoPaths = [
                process.env.VIDEO1,
                process.env.VIDEO2,
                process.env.VIDEO3
            ]

            let isVideoConnected = false

            let videoUrl1 = (deskData.value?.gameTable.cmccLine || '').split(
                '?'
            )
            let videoUrl2 = (deskData.value?.gameTable.cuccLine || '').split(
                '?'
            )

            let v1 = ''
            let v2 = ''

            const vPathOrigin = (deskData.value?.gameTable.cmccLine || '')
                .replace(/(http:|)(^|\/\/)(.*?\/)/g, '')
                .split('?')[0]

            if (videoUrl1.length > 1) v1 = videoUrl1[1]
            if (videoUrl2.length > 1) v2 = videoUrl2[1]

            videoPaths.forEach((vPath) => {
                let _videoUrl = ''

                if (vPath === process.env.VIDEO1) {
                    _videoUrl = `${vPath}/${vPathOrigin}?${v2}`
                } else {
                    _videoUrl = `${vPath}/${vPathOrigin}?${v1}`
                }

                // eslint-disable-next-line no-undef
                let _player: NodePlayer | any = new NodePlayer()

                _player.setVolume(0.0)
                _player.setView('player1')
                _player.setBufferTime(1000)
                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true
                        nodePlayer = _player
                        nodePlayer.clearView()
                        nodePlayer.setView('player2')
                        nodePlayer.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    isLoadingVideo.value = true
                                } else if (evt === 'full') {
                                    isLoadingVideo.value = false
                                }
                            }
                        )
                        nodePlayer.on('error', (e: any) => {
                            isLoadingVideo.value = true
                            setTimeout(() => {
                                nodePlayer.clearView()
                                nodePlayer.stop()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        _player.clearView()
                        _player.stop()
                    }
                })
                _player.on('error', () => {
                    //
                })
                videoPlayerCollection.push(_player)
                _player.start(_videoUrl)
            })
        }
        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }

            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.clearView()
                    _player.stop()
                }
            })

            videoPlayerCollection = []
        }

        const onToggleVideo = () => {
            // if (deskVideoOn.value) {
            //     deskVideoOn.value = false
            //     disposeVideoPlayer()
            // } else {
            //     deskVideoOn.value = true
            //     if (deskData.value) initVideoPlayer()
            // }

            store.commit(AppMutation.SET_VIDEO, !deskVideoOn.value)
        }

        const commandType = () => {
            let cmd = ''
            if (deskData.value) {
                const { commandType } = deskData.value.gameTable
                if (commandType === 0) cmd = '通用台'
                else if (commandType === 1) cmd = '电投台'
                else if (commandType === 2) cmd = '指令台'
            }

            return cmd
        }

        const handleOnMapPreview = (_index: number) => {
            if (
                _index >= 0 &&
                deskData.value &&
                deskData.value.gameTable.expand.gameRecordList.length >=
                    _index + 1
            ) {
                if (!showResultPreview.value) showResultPreview.value = true
                const _log =
                    deskData.value.gameTable.expand.gameRecordList[_index]
                setTimeout(() => {
                    emitter.emit(EventBuss.PREVIEW_RESULT, _log)
                    // console.log(_log)
                }, 200)
            }
        }

        //#endregion

        //#region Click Method Helpers
        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }
        const toggleSoundEffect = () => {
            const _isSoundOn = soundIsOn.value ? 0 : 1
            store.commit(AppMutation.SET_SOUND, !soundIsOn.value)

            if (audioPlayer) audioPlayer.isOn = _isSoundOn
        }

        const hideSwitchTable = () => {
            showSwitchDesk.value = !showSwitchDesk.value
        }

        const joinTable = () => {
            if (isFreeUser.value) return

            if (
                deskData.value &&
                deskData.value.gameTable.reserverd === 1 &&
                userCommandType.value === 1
            ) {
                emitter.emit(
                    EventBuss.TOAST_MESSAGE,
                    '此桌已被包桌，请选择其他台桌进行投注'
                )
                return
            }
            // check if has a gunner
            if (!isBindToGunner.value) {
                emitter.emit(EventBuss.TOAST_MESSAGE, '请先绑定枪手')
                // 此桌已被包桌，请选择其他台桌进行投注
                return
            }

            if (hasJoinRequest.value) return

            const _params = {
                memberId: memberid.value,
                tableId: deskData.value?.gameTable.id,
                status: 5,
                token: token.value
            }
            hasJoinRequest.value = true
            store.commit(AppMutation.SHOW_LOADING)

            changeTable(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const _responseData = response.data as ResponseData<any>
                    if (_responseData.status !== 200) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            _responseData.description!
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const callGunnerFunc = () => {
            if (deskData.value && !isLoadingCallGunner) {
                const { expand, id } = deskData.value.gameTable
                const params = {
                    juNo: expand.juNo,
                    gameNo: expand.gameNo,
                    token: token.value,
                    operateType:
                        expand.callGunner && expand.callGunner === 2 ? 1 : 2,
                    memberId: userInfo.value.id,
                    tableId: id,
                    shoeNo: expand.shoeNo
                }
                isLoadingCallGunner = true
                callGunner(params)
                    .then((response) => {
                        isLoadingCallGunner = false
                        if (response.data.status === 200) {
                            if (deskData.value) {
                                if (
                                    expand.callGunner &&
                                    expand.callGunner === 2
                                ) {
                                    deskData.value.gameTable.expand.callGunner = 1
                                    emitter.emit(
                                        EventBuss.SMALL_TOAST,
                                        '您已切换至自主投注模式'
                                    )
                                } else if (
                                    expand.callGunner &&
                                    expand.callGunner === 1
                                ) {
                                    deskData.value.gameTable.expand.callGunner = 2
                                    emitter.emit(
                                        EventBuss.SMALL_TOAST,
                                        '已切换至枪手投注模式'
                                    )
                                }
                            }
                        } else if (response.data.status === 100) {
                            emitter.emit(
                                EventBuss.SMALL_TOAST,
                                response.data.description
                            )
                        }
                    })
                    .catch(() => {
                        isLoadingCallGunner = false
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )
                    })
            }
        }

        const callOperatStatus = (_op: number) => {
            const _params = {
                memberId: memberid.value,
                tableId: deskData.value?.gameTable.id || 0,
                status: _op,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)
            operatStatus(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const callFlyBet = () => {
            const _params = {
                memberId: memberid.value,
                tableId: deskData.value?.gameTable.id || 0,
                status: 2,
                token: token.value,
                shoeNo: deskData.value?.gameTable.expand.shoeNo || 0,
                juNo: deskData.value?.gameTable.expand.juNo || 0,
                gameNo: deskData.value?.gameTable.expand.gameNo || ''
            }
            store.commit(AppMutation.SHOW_LOADING)

            flyBet(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    // console.log(response.data)
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const showTipBox = () => {
            const _evt: MessageBoxEvent = {
                name: 'tipbox',
                type: MessageBoxActionType.open
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const callReservedOrCancellReserved = () => {
            if (!tableCanReserved.value) return

            if (deskData.value!.isYourReserverd === 1) {
                callOperatStatus(8)
                return
            }

            if (deskData.value!.gameTable.reserverd !== -1) {
                const _evt: MessageBoxEvent = {
                    type: MessageBoxActionType.open,
                    name: 'confirm-booking'
                }

                emitter.emit(EventBuss.DIALOG, _evt)
                return
            }
        }

        const callPauseRequest = () => {
            // if(deskData.value?.gameTable.pauseStatus)
            // 其他会员暂停游戏，您没有权限继续游戏
            const _status = 18
            // deskData.value!.gameTable.pauseStatus === -1 ? 19 : 18

            // if (
            //     _status === 19 &&
            //     deskData.value!.gameTable.pauseMemberId !== memberid.value
            // ) {
            //     emitter.emit(
            //         EventBuss.TOAST_MESSAGE,
            //         '其他会员暂停游戏，您没有权限继续游戏'
            //     )
            //     return
            // }

            const _params = {
                memberId: memberid.value,
                tableId: deskData.value!.gameTable.id,
                status: _status,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            pauseRequest(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 200) {
                        // if (_status === 19) {
                        //     deskData.value!.gameTable.pauseStatus = 0
                        //     deskData.value!.gameTable.pauseMemberId = 0
                        // } else if (_status === 18) {
                        deskData.value!.gameTable.pauseStatus = -1
                        deskData.value!.gameTable.pauseMemberId = memberid.value
                        // }
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '您已发送暂停指令，接线员已收到您的指令信息！'
                        )
                    } else if (response.data.status === 100) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            response.data.description
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.SMALL_TOAST,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        const callOpenPokeRequest = () => {
            if (calledOpenPokeCounter === 0) {
                const _params = {
                    memberId: memberid.value,
                    tableId: deskData.value!.gameTable.id,
                    status: 15,
                    token: token.value
                }
                store.commit(AppMutation.SHOW_LOADING)

                calledOpenPokeCounter += 1
                openPokeRequest(_params)
                    .then((response) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        if (response.data.status === 100) {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                response.data.description
                            )
                        } else {
                            // value 3 dont show not sent open request yet
                            /// on success bet chek if value is 3  then change is to 4 4 need to show
                            // on click the request change to 2 to no need show
                            // reset to 3 if game startt
                            isPokerOpenFlag.value = 2
                            if (deskData.value) {
                                deskData.value.gameTable.expand.openPoke = 1
                            }
                        }
                    })
                    .catch(() => {
                        calledOpenPokeCounter = 0
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )
                    })
            }
        }
        //#endregion

        //#region Timer Helper Methods
        const startCountDown = () => {
            // console.log('count')
            if (typeof countDownInterval !== 'number') {
                currentCount.value =
                    deskData.value!.gameTable.expand.daoJiShiBetTime

                if (currentCount.value > 20) {
                    audioPlayer.Play(SOUNDKEY.START_BET)
                }

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()

                        if (userCommandType.value !== 1) {
                            audioPlayer.Play(SOUNDKEY.STOP_BET)
                            emitter.emit(EventBuss.GAMESTATUS, 2)
                        }
                    } else {
                        if (
                            currentCount.value <= 11 &&
                            currentCount.value > 0
                        ) {
                            audioPlayer.Play(SOUNDKEY.TICK)
                        }
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const stopCountDown = () => {
            if (typeof countDownInterval === 'number') {
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
                if (deskData.value) {
                    deskData.value!.gameTable.status = 2
                    deskData.value!.gameTable.expand.daoJiShiBetTime = -1
                }
            }
            enableOpenPoker.value = false
            calledOpenPokeCounter = 0
        }
        //#endregion

        const showSettingDialog = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'setting'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }
        //#region WebSocket
        const handleSocketMessage = (_message: SocketMessage | any) => {
            const { type, gameTable } = _message
            const _type = type.toUpperCase()

            if (_type === SOCKET_MESSAGE.NEW_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.expand.shoeNo = sDesk.expand.shoeNo
                    _gtableCopy.expand.juNo = sDesk.expand.juNo
                    _gtableCopy.expand.gameNo = sDesk.expand.gameNo
                    _gtableCopy.expand.daoJiShiBetTime =
                        sDesk.expand.daoJiShiBetTime

                    // reset all data
                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    // reset card result
                    _gtableCopy.expand.playerOne = undefined
                    _gtableCopy.expand.playerTwo = undefined
                    _gtableCopy.expand.playerThree = undefined
                    _gtableCopy.expand.bankerOne = undefined
                    _gtableCopy.expand.bankerTwo = undefined
                    _gtableCopy.expand.bankerThree = undefined
                    _gtableCopy.expand.playerCount = undefined
                    _gtableCopy.expand.bankerCount = undefined
                    _gtableCopy.expand.whoWin = -1

                    _gtableCopy.status = 1
                    _gtableCopy.expand.stopBet = 0
                    _gtableCopy.expand.openPoke = -1
                    // isPokerOpenFlag.value = 3
                    // open card Flag
                    isPokerOpenFlag.value = 4

                    deskData.value!.gameTable = _gtableCopy
                    emitter.emit(EventBuss.GAMESTATUS, 1)
                }
            } else if (_type === SOCKET_MESSAGE.END_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.expand.playerOne = sDesk.expand.playerOne
                    _gtableCopy.expand.playerTwo = sDesk.expand.playerTwo
                    _gtableCopy.expand.playerThree = sDesk.expand.playerThree
                    _gtableCopy.expand.bankerOne = sDesk.expand.bankerOne
                    _gtableCopy.expand.bankerTwo = sDesk.expand.bankerTwo
                    _gtableCopy.expand.bankerThree = sDesk.expand.bankerThree
                    _gtableCopy.expand.playerCount = sDesk.expand.playerCount
                    _gtableCopy.expand.bankerCount = sDesk.expand.bankerCount
                    _gtableCopy.expand.whoWin = sDesk.expand.whoWin
                    _gtableCopy.expand.resultList = sDesk.expand.resultList
                    _gtableCopy.expand.gameRecordList =
                        sDesk.expand.gameRecordList

                    // reset bet data
                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]
                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberid.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            if (_memberResultInfo.winResult !== 0) {
                                let _amtStr = '0'

                                if (_memberResultInfo.winResult > 0)
                                    _amtStr = `+${_memberResultInfo.winResult}`
                                else
                                    _amtStr = String(
                                        _memberResultInfo.winResult
                                    )

                                emitter.emit(EventBuss.SMALL_TOAST, _amtStr)
                            }
                        }
                    }

                    deskData.value!.gameTable = _gtableCopy

                    if (typeof showCardTimeout === 'number') {
                        clearTimeout(showCardTimeout)
                        showCardTimeout = false
                        showCardDisplay.value = false
                    }
                    cardResultKey.value = Date.now()
                    showCardDisplay.value = true
                    showCardTimeout = setTimeout(() => {
                        showCardDisplay.value = false
                    }, 6000)

                    if (refBetGroup.value) {
                        setTimeout(() => {
                            refBetGroup.value.endGame()
                        }, 250)
                    }
                }
            } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.expand.daoJiShiBetTime = 0
                    _gtableCopy.status = 2
                    deskData.value!.gameTable = _gtableCopy
                    if (userCommandType.value !== 1) {
                        audioPlayer.Play(SOUNDKEY.STOP_BET)
                        emitter.emit(EventBuss.GAMESTATUS, 2)
                    }
                }
            } else if (_type === SOCKET_MESSAGE.CANCEL_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.status = sDesk.status

                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    _gtableCopy.expand.stopBet = 1
                    _gtableCopy.expand.openPoke = -1

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]

                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberid.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            if (refBetGroup.value) {
                                setTimeout(() => {
                                    refBetGroup.value.endGame()
                                }, 250)
                            }
                        }
                    }

                    deskData.value!.gameTable = _gtableCopy
                }
            } else if (_type === SOCKET_MESSAGE.NEW_SHOE) {
                const sDesk = gameTable!
                if (isSameTable(sDesk) && deskData.value) {
                    sDesk.expand.memberStatus =
                        deskData.value.gameTable.expand.memberStatus
                    deskData.value.gameTable = sDesk
                }
            } else if (_type === SOCKET_MESSAGE.BET) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.expand.xianAmount = sDesk.expand.xianAmount
                    _gtableCopy.expand.xianDuiAmount =
                        sDesk.expand.xianDuiAmount
                    _gtableCopy.expand.zhuangAmount = sDesk.expand.zhuangAmount
                    _gtableCopy.expand.zhuangDuiAmount =
                        sDesk.expand.zhuangDuiAmount
                    _gtableCopy.expand.heAmount = sDesk.expand.heAmount
                    if (sDesk.expand.memberId === memberid.value) {
                        _gtableCopy.expand.betFormList =
                            sDesk.expand.betFormList
                        store.commit(AppMutation.SET_COIN, sDesk.expand.amount)
                        // store.commit(AppMutation.SET_CLIENT_TIME_OUT,15)
                    }
                    deskData.value!.gameTable = _gtableCopy
                }
            } else if (_type === SOCKET_MESSAGE.GUNNER_BET) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.expand.xianAmount = sDesk.expand.xianAmount
                    _gtableCopy.expand.xianDuiAmount =
                        sDesk.expand.xianDuiAmount
                    _gtableCopy.expand.zhuangAmount = sDesk.expand.zhuangAmount
                    _gtableCopy.expand.zhuangDuiAmount =
                        sDesk.expand.zhuangDuiAmount
                    _gtableCopy.expand.heAmount = sDesk.expand.heAmount

                    if (sDesk.expand.memberId === memberid.value) {
                        _gtableCopy.expand.betFormList =
                            sDesk.expand.betFormList

                        deskData.value!.gameTable = _gtableCopy
                        store.commit(AppMutation.SET_COIN, sDesk.expand.amount)
                        // store.commit(AppMutation.SET_CLIENT_TIME_OUT,15)

                        if (refBetGroup.value) {
                            setTimeout(() => {
                                refBetGroup.value.resetBetUI()
                            }, 250)
                        }
                    }
                }
            } else if (_type === SOCKET_MESSAGE.GUNNER_VERIFY) {
                if (
                    deskData.value &&
                    deskData.value.gameTable.id === _message.data!.tableId
                ) {
                    emitter.emit(EventBuss.SMALL_TOAST, _message.data.content)
                }

                if (_message.data!.status === 10) {
                    store.commit(AppMutation.SET_IS_GUNNER, -1)
                } else if (_message.data!.status === 5 && deskData.value) {
                    if (
                        _message.data!.tableId ===
                            deskData.value.gameTable.id &&
                        _message.data!.verifyStatus === 1
                    ) {
                        const {
                            isHavePeopleReserverd,
                            isYourReserverd,
                            tableId
                        } = _message.data!
                        deskData.value.gameTable.expand.memberStatus =
                            _message.data.gameTable.expand.memberStatus

                        if (memberid.value === _message.data!.memberId) {
                            deskData.value.isYourReserverd =
                                _message.data!.isYourReserverd
                            deskData.value.reserverdType =
                                _message.data!.reserverdType
                        }

                        if (isHavePeopleReserverd === -1)
                            deskData.value.gameTable.reserverd = 0
                        else if (isHavePeopleReserverd >= 1)
                            deskData.value.gameTable.reserverd = 1

                        deskData.value.isYourReserverd = isYourReserverd
                    } else {
                        deskData.value.gameTable.expand.memberStatus = 0
                    }

                    if (_message.data!.verifyStatus === 1) {
                        store.commit(
                            AppMutation.SET_DESK,
                            _message.data.tableId
                        )
                    }
                    hasJoinRequest.value = false
                } else if (_message.data!.status === 9) {
                    store.commit(AppMutation.SET_IS_GUNNER, 0)

                    if (deskData.value) {
                        deskData.value.gameTable.expand.memberStatus = 0
                        deskData.value.gameTable.expand.callGunner = 2
                    }

                    if (_message.data.verifyStatus === 1) {
                        store.commit(AppMutation.SET_USER_GUNNER_BIND, false)
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            _message.data.content
                        )
                        router.push({ name: ROUTES.LOBY })
                    }
                } else if (
                    _message.data!.status === 8 ||
                    _message.data!.status === 3
                ) {
                    const {
                        isHavePeopleReserverd,
                        isYourReserverd,
                        tableId,
                        reserverdType
                    } = _message.data!
                    if (
                        deskData.value &&
                        deskData.value.gameTable.id === tableId &&
                        _message.data!.verifyStatus === 1
                    ) {
                        if (isHavePeopleReserverd === -1)
                            deskData.value.gameTable.reserverd = 0
                        else if (isHavePeopleReserverd >= 1)
                            deskData.value.gameTable.reserverd = 1

                        deskData.value.isYourReserverd = isYourReserverd
                        deskData.value.reserverdType = reserverdType

                        if (
                            _message.data!.status === 8 &&
                            _message.data!.memberId === userInfo.value.id &&
                            isHavePeopleReserverd !== -1
                        ) {
                            kickUserOffTheTable()
                        }
                    }
                } else if (_message.data!.status === 17) {
                    if (
                        deskData.value &&
                        deskData.value.gameTable.id === _message.data!.tableId
                    ) {
                        if (memberid.value === _message.data!.memberId) {
                            deskData.value.isYourReserverd =
                                _message.data!.isYourReserverd
                        }

                        if (_message.data!.isHavePeopleReserverd === -1) {
                            deskData.value.gameTable.reserverd = 0
                            deskData.value.reserverdType = undefined
                        } else if (_message.data!.isHavePeopleReserverd === 1) {
                            deskData.value.gameTable.reserverd = 1
                            deskData.value.reserverdType =
                                _message.data!.reserverdType
                        }
                    }
                } else if (_message.data!.status === 16) {
                    if (
                        deskData.value &&
                        deskData.value.gameTable.id === _message.data!.tableId
                    ) {
                        if (memberid.value === _message.data!.memberId) {
                            deskData.value.isYourReserverd =
                                _message.data!.isYourReserverd
                        }

                        if (_message.data!.isHavePeopleReserverd === -1) {
                            deskData.value.gameTable.reserverd = 0
                            deskData.value.reserverdType = undefined
                        } else if (_message.data!.isHavePeopleReserverd === 1) {
                            deskData.value.gameTable.reserverd = 1
                            deskData.value.reserverdType =
                                _message.data!.reserverdType
                            // TODO: test
                            if (_message.data!.isYourReserverd === -1) {
                                kickUserOffTheTable()
                            }
                        }
                    }
                }
            } else if (_type === SOCKET_MESSAGE.GUNNER_GIVEFREE) {
                const sDesk = gameTable!
                if (_message.data.tableId === deskData.value!.gameTable.id) {
                    const _evt: MessageBoxEvent = {
                        type: MessageBoxActionType.open,
                        name: 'tipbox-concent'
                    }
                    emitter.emit(EventBuss.DIALOG, _evt)
                    setTimeout(() => {
                        const {
                            feeAmount,
                            tableId,
                            title,
                            content,
                            tableNo,
                            memberId,
                            status
                        } = _message.data
                        emitter.emit(EventBuss.TIP_CONCENT_DATA, {
                            feeAmount,
                            tableId,
                            title,
                            content,
                            tableNo,
                            memberId,
                            status
                        })
                    }, 150)
                }
            } else if (_type === SOCKET_MESSAGE.MEMBER_PLAY && deskData.value) {
                const { memberId, status, tableNo, content } = _message.data

                emitter.emit(EventBuss.SMALL_TOAST, content)
                // play
                if (
                    status === 19 &&
                    tableNo === deskData.value.gameTable.tableNo
                ) {
                    deskData.value.gameTable.pauseStatus = 0
                    deskData.value.gameTable.pauseMemberId = 0
                } else if (status === 18) {
                    deskData.value.gameTable.pauseStatus = -1
                    deskData.value.gameTable.pauseMemberId = memberId
                }
            } else if (_type === SOCKET_MESSAGE.TABLE_VISIT_STATUS) {
                const _tbId = (_message as any).tableId
                if (deskData.value && deskData.value.gameTable.id === _tbId) {
                    deskData.value.gameTable.visitCode = (
                        _message as any
                    ).visitCode

                    //  deskData.value.isYourReserverd === -1

                    if (deskData.value.gameTable.reserverd === 1) {
                        if (
                            userInfo.value &&
                            deskData.value.gameTable.visitCode
                        ) {
                            const visitCodes =
                                deskData.value.gameTable.visitCode.split(',')
                            if (visitCodes.indexOf(userInfo.value.code) == -1) {
                                kickUserOffTheTable()
                                return
                            }
                        }
                    }
                }
            } else if (_type === SOCKET_MESSAGE.STOP_BET) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.expand.stopBet = 1
                    // _gtableCopy.expand.openPoke = 1
                    _gtableCopy.expand.openPoke = -1
                    deskData.value!.gameTable = _gtableCopy

                    if (refBetGroup.value) {
                        setTimeout(() => {
                            refBetGroup.value.resetTempBet()
                        }, 250)
                    }

                    if (userCommandType.value === 1) {
                        audioPlayer.Play(SOUNDKEY.STOP_BET)
                        emitter.emit(EventBuss.GAMESTATUS, 2)
                    }
                }
            }
        }

        const isSameTable = (_table: TableData): boolean => {
            if (deskData.value && deskData.value.gameTable.id === _table.id)
                return true
            else return false
        }

        const kickUserOffTheTable = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'prompt'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
            setTimeout(() => {
                emitter.emit(EventBuss.MESSAGE, {
                    message: '当前台桌已关闭访问，请选择其他台桌进行投注',
                    callback: () => {
                        //
                    }
                })
            }, 100)
            router.push({
                name: ROUTES.LOBY
            })
        }
        //#endregion

        const redCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.redCount
            return 0
        })

        const blueCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.blueCount
            return 0
        })

        const greenCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.tieCount
            return 0
        })

        const redPairCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.redPairCount
            return 0
        })

        const bluePairCount = computed(() => {
            if (roadMapData.value) return roadMapData.value.bluePairCount
            return 0
        })

        const token = computed(() => store.getters['token'])
        const user = computed(() => store.getters['user'])
        const coin = computed(() => store.getters['coin'])
        const memberid = computed(() => store.getters['memberid'])
        const soundIsOn = computed(() => store.getters['soundEffect'] === 1)
        const videoIsOn = computed(() => store.getters['videoIsOn'])

        const isMobile = computed(() => store.getters['isMobile'])

        const deviceOrientation = computed(
            () => store.getters['deviceOrientation']
        )

        const mapData = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.expand.resultList
            return []
        })

        const timerColor = computed(() => {
            let _color = ''
            if (currentCount.value > 20) {
                _color = 'green'
            } else if (currentCount.value > 10 && currentCount.value <= 20) {
                _color = 'orange'
            } else if (currentCount.value <= 10 && currentCount.value >= 0) {
                _color = 'red'
            }

            return _color
        })

        const isFreeUser = computed(() => store.getters['isFreeUser'])
        const visitorTable = computed(
            (): number[] => store.getters['visitorTable']
        )

        const shoeRound = computed(() => {
            if (deskData.value)
                return `${deskData.value.gameTable.expand.shoeNo}/${deskData.value.gameTable.expand.juNo}`

            return '0'
        })

        const deskOptions = computed(
            (): Array<{ gameType: number; name: string; id: number }> =>
                store.getters['deskOptions']
        )

        const redAskLabel = computed(() => {
            let _label = ''

            return _label
        })

        const blueAskLabel = computed(() => {
            let _label = ''

            return _label
        })

        const gameType = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.gameTypeNo.toUpperCase()

            return 'G002'
        })

        const redBlueLimit = computed(() => {
            if (deskData.value) {
                const { downLimit, upLimit } = deskData.value.gameTable
                return `${downLimit}-${upLimit}`
            }
            return '0:0'
        })
        const pairLimit = computed(() => {
            if (deskData.value) {
                const { pairDownLimit, pairUpLimit } = deskData.value.gameTable
                return `${pairDownLimit}-${pairUpLimit}`
            }
            return '0:0'
        })
        const greenLimit = computed(() => {
            if (deskData.value) {
                const { tieDownLimit, tieUpLimit } = deskData.value.gameTable
                return `${tieDownLimit}-${tieUpLimit}`
            }
            return '0:0'
        })

        const tableName = computed(() => {
            if (deskData.value) {
                const { gameTypeNo, tableNo } = deskData.value.gameTable

                if (gameTypeNo.toUpperCase() === 'G002')
                    return `${t('baccarattable')} ${tableNo} (${commandType()})`
                else
                    return `${t(
                        'dragontigertable'
                    )} ${tableNo} (${commandType()})`
            }
            return ''
        })

        const gameid = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.expand.gameNo
            }

            return '0'
        })

        const isReserved = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.reserverd > 0
            }

            return false
        })

        const commandTypeAndGameId = computed(() => {
            if (deskData.value) {
                return `${commandType()} (${t('gameid')}: ${
                    deskData.value.gameTable.expand.gameNo
                })`
            }
            return ''
        })

        const cardResultData = computed(() => {
            if (deskData.value) return deskData.value.gameTable
            return undefined
        })

        const gameTable = computed(() => {
            if (deskData.value) return deskData.value.gameTable

            return undefined
        })

        const callGunnerText = computed(() => {
            if (deskData.value) {
                const callGunner = deskData.value.gameTable.expand.callGunner
                if (callGunner && callGunner === 2) return '无声'
                else if (callGunner && callGunner === 1) return '有声'
            }

            return '无声'
        })

        const isInThisTable = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.expand.memberStatus === 3
            return false
        })

        const isBindToGunner = computed(() => store.getters['isGunner'] !== 0)

        const isfullScreen = computed(() => store.getters['isfullScreen'])

        const userCommandType = computed(() => store.getters['commandType'])
        const userInfo = computed((): UserInfo => store.getters['userInfo'])
        const tableCanReserved = computed(() => {
            if (deskData.value) {
                if (deskData.value.isYourReserverd === 1) return true

                if (
                    userCommandType.value === 1 &&
                    deskData.value.gameTable.reserverd === 1
                )
                    return false

                // if(userCommandType.value ===2 && deskData.value.gameTable.reserverd === 1 && )
                if (
                    deskData.value.reserverdType &&
                    deskData.value.reserverdType === 1 &&
                    userCommandType.value === 2
                )
                    return false
                return deskData.value.gameTable.reserverd !== -1
            }

            return false
        })

        const gameIsPaused = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.pauseStatus === -1
            }
            return false
        })

        const vipButtontext = computed(() => {
            if (deskData.value)
                return deskData.value.isYourReserverd === 1
                    ? '取消包台'
                    : '包台'
            return '包台'
        })

        const showTableControls = computed((): boolean => {
            if (isFreeUser.value) return false
            if (userCommandType.value === 2) return true

            if (
                deskData.value &&
                deskData.value.reserverdType &&
                deskData.value.reserverdType === 2 &&
                userCommandType.value === 1
            ) {
                return false
            }

            if (isBindToGunner.value && isInThisTable.value) {
                return true
            }

            if (deskData.value && deskData.value.isYourReserverd === 1)
                return true

            return false
        })

        const showChipControls = computed((): boolean => {
            if (isFreeUser.value) return false
            if (userCommandType.value === 2) return true

            if (
                deskData.value &&
                deskData.value.reserverdType &&
                deskData.value.reserverdType === 2 &&
                userCommandType.value === 1
            ) {
                return false
            }

            if (isBindToGunner.value && isInThisTable.value) {
                return true
            }

            return false
        })

        const isYoureReserved = computed((): boolean => {
            if (deskData.value) return deskData.value.isYourReserverd === 1
            return false
        })
        const fullScreenAvailable = computed(() => screenfull.isEnabled)

        const enableOpenPokerOP = computed(() => {
            if (deskData.value && userCommandType.value !== 1) {
                return (
                    deskData.value.gameTable.status === 1 &&
                    isPokerOpenFlag.value === 4
                )
                // return true
            } else if (
                deskData.value &&
                userCommandType.value === 1 &&
                deskData.value.gameTable.expand.stopBet !== 1
            ) {
                return (
                    (deskData.value.gameTable.status === 1 ||
                        deskData.value.gameTable.status === 2) &&
                    isPokerOpenFlag.value === 4
                )
            }

            return false
        })

        const btnJoinTableLabel = computed(() => {
            if (isFreeUser.value) return '访客登录，无法进行投注'
            else {
                return hasJoinRequest.value
                    ? '等待枪手处理中...'
                    : '点击转入此桌'
            }
        })

        watch(
            () => deviceOrientation.value,
            () => {
                showSwitcher.value = false
            }
        )

        watch(
            () => videoIsOn.value,
            (v) => {
                if (v !== deskVideoOn.value) {
                    deskVideoOn.value = v

                    if (deskVideoOn.value) {
                        if (deskData.value) initVideoPlayer()
                    } else {
                        disposeVideoPlayer()
                    }
                }
            }
        )

        watch(
            () => deskData.value,
            (v) => {
                if (v) {
                    if (v.gameTable.expand.daoJiShiBetTime > 0) {
                        startCountDown()
                    } else {
                        stopCountDown()
                    }
                }
            },
            { deep: true }
        )

        return {
            isMobile,
            mapData,
            roadmapview1,
            roadmapview2,
            roadMapData,
            nextRedRoadMapSummaryData,
            nextBlueRoadMapSummaryData,

            currentCount,
            timerColor,
            user,
            coin,
            soundIsOn,
            videoIsOn,
            deskVideoOn,

            shoeRound,
            deskNotice,
            deskNoticeKey,
            blueCount,
            redCount,
            greenCount,
            bluePairCount,
            redPairCount,
            redBlueLimit,
            pairLimit,
            greenLimit,
            tableName,
            gameid,
            isReserved,
            commandTypeAndGameId,
            cardResultData,
            cardResultKey,
            isBindToGunner,
            isInThisTable,
            callGunnerText,
            hasJoinRequest,
            tableCanReserved,
            deskMapKey,
            showSimpleResult,
            showCardDisplay,
            showMediaControl,
            showSwitchDesk,
            vipButtontext,
            userCommandType,
            gameIsPaused,
            showTableControls,
            showChipControls,
            isYoureReserved,
            enableOpenPoker,
            showResultPreview,
            btnJoinTableLabel,

            deskOptions,
            redAskLabel,
            blueAskLabel,
            gameType,
            isLoadingVideo,
            isScaleVideo,
            isfullScreen,
            fullScreenAvailable,

            gameTable,
            betViewKey,
            refBetGroup,
            enableOpenPokerOP,
            onToggleMediaControl,
            hideSwitcher,
            onRoadMapDataParsed,
            predict,
            t,
            onToggleVideo,
            showSettingDialog,
            requestFullScreen,
            toggleSoundEffect,
            hideSwitchTable,
            onSwitchTable,
            joinTable,
            callGunnerFunc,
            callOperatStatus,
            callFlyBet,
            showTipBox,
            callReservedOrCancellReserved,
            callPauseRequest,
            onPlaceTempBetOrClear,
            onBetSuccess,
            callOpenPokeRequest,
            handleOnMapPreview
        }
    }
})
