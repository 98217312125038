import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-056f7a3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-table-item-root" }
const _hoisted_2 = { class: "switch-table-item-header" }
const _hoisted_3 = { class: "table-name" }
const _hoisted_4 = { class: "limit" }
const _hoisted_5 = { class: "road-map-sumary" }
const _hoisted_6 = ["data-label"]
const _hoisted_7 = ["data-label"]
const _hoisted_8 = {
  class: "road-map-summary-item",
  "data-color": "green",
  "data-label": "和"
}
const _hoisted_9 = {
  key: 0,
  class: "road-map-summary-item",
  "data-color": "red",
  "data-label": "对"
}
const _hoisted_10 = {
  key: 1,
  class: "road-map-summary-item",
  "data-color": "blue",
  "data-label": "对"
}
const _hoisted_11 = { class: "item-body" }
const _hoisted_12 = {
  key: 0,
  class: "is-at-this-table"
}
const _hoisted_13 = {
  key: 0,
  class: "is-reserved"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_road_map = _resolveComponent("road-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tableName), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["timer-count-down", _ctx.timerColor])
      }, _toDisplayString(_ctx.currentCount !== -1 ? _ctx.currentCount : ''), 3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.mData.downLimit + '-' + _ctx.mData.upLimit), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "road-map-summary-item",
          "data-color": "red",
          "data-label": 
                        _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? '庄' : '龙'
                    
        }, _toDisplayString(_ctx.redCount), 9, _hoisted_6),
        _createElementVNode("div", {
          class: "road-map-summary-item",
          "data-color": "blue",
          "data-label": 
                        _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? '闲' : '虎'
                    
        }, _toDisplayString(_ctx.blueCount), 9, _hoisted_7),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.greenCount), 1),
        (_ctx.mData.gameTypeNo.toUpperCase() === 'G002')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.redPairCount), 1))
          : _createCommentVNode("", true),
        (_ctx.mData.gameTypeNo.toUpperCase() === 'G002')
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.bluePairCount), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_road_map, {
        class: "road-map",
        mapData: _ctx.mData.expand.resultList,
        gameType: _ctx.mData.gameTypeNo.toUpperCase() === 'G002' ? 1 : 2,
        onRoadMapData: _ctx.onRoadMapDataParsed,
        beadRowCount: 10
      }, null, 8, ["mapData", "gameType", "onRoadMapData"]),
      (_ctx.isAtThisTable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, "您在此桌"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.mData.reserverd > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13))
      : _createCommentVNode("", true)
  ]))
}