
import {
    computed,
    defineComponent,
    Emitter,
    HallData,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    ResponseData,
    SocketMessage,
    TableData
} from 'vue'
import SwitchTableItem from '@/components/switch-table-item.vue'
import { getHallData } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, SOCKET_MESSAGE } from '@/types/global'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'

export default defineComponent({
    emits: ['enter-table'],
    components: {
        SwitchTableItem
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const desks = ref<Array<TableData>>([])
        const tabId = ref<number>(1)
        const router = useRouter()

        //#region  Vue Methods
        onMounted(() => {
            loadData()
        })

        onBeforeMount(() => {
            // listen to websocket events
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })

        onBeforeUnmount(() => {
            // unregister events
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
        })
        //#endregion

        const switchTab = (_tabId: number) => {
            if (_tabId !== tabId.value) {
                tabId.value = _tabId

                loadData()
            }
        }

        const loadData = (_forceReload = false) => {
            store.commit(AppMutation.SHOW_LOADING)

            const _params: any = {
                token: token.value,
                commandType: commandType.value
            }

            if (tabId.value === 2) {
                _params.gameTypeNo = 'G002'
            } else if (tabId.value === 3) {
                _params.gameTypeNo = 'G003'
            }

            getHallData(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        desks.value = responseData.data!.gameTableList
                    } else if (responseData.status === 100) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch((e) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, '网络错误')
                    console.log(e)
                })
        }

        const handleSocketMessage = (_message: SocketMessage) => {
            if (
                _message.type.toUpperCase() === SOCKET_MESSAGE.NEW_GAME ||
                _message.type.toUpperCase() === SOCKET_MESSAGE.END_GAME ||
                _message.type.toUpperCase() === SOCKET_MESSAGE.STOP_GAME
            ) {
                const _desk = desks.value.find(
                    (_d) => _d.id === _message.gameTable!.id
                )
                if (_desk) {
                    const _index = desks.value.indexOf(_desk)
                    if (_index !== -1) desks.value[_index] = _message.gameTable!
                }
            } else if (
                _message.type.toUpperCase() ===
                SOCKET_MESSAGE.TABLE_VISIT_STATUS
            ) {
                const _table = desks.value.find(
                    (x) => x.id === (_message as any).tableId
                )
                if (_table) {
                    const _index = desks.value.indexOf(_table)
                    if (_index !== -1) {
                        desks.value[_index].visitCode = (
                            _message as any
                        ).visitCode
                    }
                }
            } else if (
                _message.type.toUpperCase() === SOCKET_MESSAGE.GUNNER_VERIFY
            ) {
                if (_message.data.status == 16 || _message.data.status === 17) {
                    if (_message.data.verifyStatus === 1) {
                        // TODO: Check Reservation Status
                       
                        const _table1 = desks.value.find(
                            (x) => x.id === _message.data.tableId
                        )
                        if (_table1) {
                            const _index2 = desks.value.indexOf(_table1)
                            if (
                                _index2 !== -1 &&
                                _message.data!.isHavePeopleReserverd === 1
                            ) {
                                desks.value[_index2].reserverd = 1
                            } else {
                                desks.value[_index2].reserverd = -1
                            }
                        }
                    }
                }
            }
        }

        const token = computed(() => store.getters['token'])
        const commandType = computed(() => store.getters['commandType'])
        const isFreeUser = computed(() => store.getters['isFreeUser'])
        const visitorTable = computed(
            (): number[] => store.getters['visitorTable']
        )

        const filteredDesk = computed(() => {
            if (isFreeUser.value) {
                return desks.value.filter(
                    (_desk) => visitorTable.value.indexOf(_desk.id) !== -1
                )
            } else {
                return desks.value
            }
        })

        return {
            tabId,
            desks,
            filteredDesk,
            switchTab
        }
    }
})
