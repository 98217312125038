import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d08fff3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-result-preview-root" }
const _hoisted_2 = { class: "card-result-preview-content" }
const _hoisted_3 = { class: "card-result-preview-body" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = {
  key: 0,
  class: "card-points"
}
const _hoisted_7 = { class: "center" }
const _hoisted_8 = ["data-win"]
const _hoisted_9 = { class: "right" }
const _hoisted_10 = {
  key: 0,
  class: "card-points"
}
const _hoisted_11 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card-result-preview-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
      }, " 关闭 "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.showBlue3Card)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["third-card", `h1_${_ctx.blueTHirdCard}`])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.showBlue2Card)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["second-card", `v1_${_ctx.blueSecondCard}`])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.showBlue1Card)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["first-card", `v1_${_ctx.blueFirstCard}`])
                }, null, 2))
              : _createCommentVNode("", true)
          ]),
          (_ctx.showBlueTotal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.blueTotal), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.showWinner)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "win",
                "data-win": _ctx.winner
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.showRedTotal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.redTotal), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.showRed1Card)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["first-card", `v1_${_ctx.redFirstCard}`])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.showRed2Card)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["second-card", `v1_${_ctx.redSecondCard}`])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.showRed3Card)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["third-card", `h1_${_ctx.redThirdCard}`])
                }, null, 2))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}